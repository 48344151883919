exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-heroes-aisov-tsx": () => import("./../../../src/pages/heroes/aisov.tsx" /* webpackChunkName: "component---src-pages-heroes-aisov-tsx" */),
  "component---src-pages-heroes-alehno-tsx": () => import("./../../../src/pages/heroes/alehno.tsx" /* webpackChunkName: "component---src-pages-heroes-alehno-tsx" */),
  "component---src-pages-heroes-aleshkin-tsx": () => import("./../../../src/pages/heroes/aleshkin.tsx" /* webpackChunkName: "component---src-pages-heroes-aleshkin-tsx" */),
  "component---src-pages-heroes-alyhov-tsx": () => import("./../../../src/pages/heroes/alyhov.tsx" /* webpackChunkName: "component---src-pages-heroes-alyhov-tsx" */),
  "component---src-pages-heroes-andreev-tsx": () => import("./../../../src/pages/heroes/andreev.tsx" /* webpackChunkName: "component---src-pages-heroes-andreev-tsx" */),
  "component---src-pages-heroes-astafiev-tsx": () => import("./../../../src/pages/heroes/astafiev.tsx" /* webpackChunkName: "component---src-pages-heroes-astafiev-tsx" */),
  "component---src-pages-heroes-belonoshenko-tsx": () => import("./../../../src/pages/heroes/belonoshenko.tsx" /* webpackChunkName: "component---src-pages-heroes-belonoshenko-tsx" */),
  "component---src-pages-heroes-berdnikov-tsx": () => import("./../../../src/pages/heroes/berdnikov.tsx" /* webpackChunkName: "component---src-pages-heroes-berdnikov-tsx" */),
  "component---src-pages-heroes-berezhanskiy-tsx": () => import("./../../../src/pages/heroes/berezhanskiy.tsx" /* webpackChunkName: "component---src-pages-heroes-berezhanskiy-tsx" */),
  "component---src-pages-heroes-bliznuk-tsx": () => import("./../../../src/pages/heroes/bliznuk.tsx" /* webpackChunkName: "component---src-pages-heroes-bliznuk-tsx" */),
  "component---src-pages-heroes-boronin-tsx": () => import("./../../../src/pages/heroes/boronin.tsx" /* webpackChunkName: "component---src-pages-heroes-boronin-tsx" */),
  "component---src-pages-heroes-burnyshov-tsx": () => import("./../../../src/pages/heroes/burnyshov.tsx" /* webpackChunkName: "component---src-pages-heroes-burnyshov-tsx" */),
  "component---src-pages-heroes-chaikovskiy-tsx": () => import("./../../../src/pages/heroes/chaikovskiy.tsx" /* webpackChunkName: "component---src-pages-heroes-chaikovskiy-tsx" */),
  "component---src-pages-heroes-chegodaev-tsx": () => import("./../../../src/pages/heroes/chegodaev.tsx" /* webpackChunkName: "component---src-pages-heroes-chegodaev-tsx" */),
  "component---src-pages-heroes-chernomashintsev-tsx": () => import("./../../../src/pages/heroes/chernomashintsev.tsx" /* webpackChunkName: "component---src-pages-heroes-chernomashintsev-tsx" */),
  "component---src-pages-heroes-dmitriev-tsx": () => import("./../../../src/pages/heroes/dmitriev.tsx" /* webpackChunkName: "component---src-pages-heroes-dmitriev-tsx" */),
  "component---src-pages-heroes-eliseev-tsx": () => import("./../../../src/pages/heroes/eliseev.tsx" /* webpackChunkName: "component---src-pages-heroes-eliseev-tsx" */),
  "component---src-pages-heroes-firstov-tsx": () => import("./../../../src/pages/heroes/firstov.tsx" /* webpackChunkName: "component---src-pages-heroes-firstov-tsx" */),
  "component---src-pages-heroes-geyko-tsx": () => import("./../../../src/pages/heroes/geyko.tsx" /* webpackChunkName: "component---src-pages-heroes-geyko-tsx" */),
  "component---src-pages-heroes-glagolev-tsx": () => import("./../../../src/pages/heroes/glagolev.tsx" /* webpackChunkName: "component---src-pages-heroes-glagolev-tsx" */),
  "component---src-pages-heroes-golovan-tsx": () => import("./../../../src/pages/heroes/golovan.tsx" /* webpackChunkName: "component---src-pages-heroes-golovan-tsx" */),
  "component---src-pages-heroes-grib-tsx": () => import("./../../../src/pages/heroes/grib.tsx" /* webpackChunkName: "component---src-pages-heroes-grib-tsx" */),
  "component---src-pages-heroes-gusev-tsx": () => import("./../../../src/pages/heroes/gusev.tsx" /* webpackChunkName: "component---src-pages-heroes-gusev-tsx" */),
  "component---src-pages-heroes-hamidullin-tsx": () => import("./../../../src/pages/heroes/hamidullin.tsx" /* webpackChunkName: "component---src-pages-heroes-hamidullin-tsx" */),
  "component---src-pages-heroes-haritonov-tsx": () => import("./../../../src/pages/heroes/haritonov.tsx" /* webpackChunkName: "component---src-pages-heroes-haritonov-tsx" */),
  "component---src-pages-heroes-hitov-tsx": () => import("./../../../src/pages/heroes/hitov.tsx" /* webpackChunkName: "component---src-pages-heroes-hitov-tsx" */),
  "component---src-pages-heroes-hotchenkov-tsx": () => import("./../../../src/pages/heroes/hotchenkov.tsx" /* webpackChunkName: "component---src-pages-heroes-hotchenkov-tsx" */),
  "component---src-pages-heroes-ilynov-tsx": () => import("./../../../src/pages/heroes/ilynov.tsx" /* webpackChunkName: "component---src-pages-heroes-ilynov-tsx" */),
  "component---src-pages-heroes-ivanov-s-tsx": () => import("./../../../src/pages/heroes/ivanovS.tsx" /* webpackChunkName: "component---src-pages-heroes-ivanov-s-tsx" */),
  "component---src-pages-heroes-ivanov-tsx": () => import("./../../../src/pages/heroes/ivanov.tsx" /* webpackChunkName: "component---src-pages-heroes-ivanov-tsx" */),
  "component---src-pages-heroes-jetygenov-tsx": () => import("./../../../src/pages/heroes/jetygenov.tsx" /* webpackChunkName: "component---src-pages-heroes-jetygenov-tsx" */),
  "component---src-pages-heroes-klavdienko-tsx": () => import("./../../../src/pages/heroes/klavdienko.tsx" /* webpackChunkName: "component---src-pages-heroes-klavdienko-tsx" */),
  "component---src-pages-heroes-kolyada-tsx": () => import("./../../../src/pages/heroes/kolyada.tsx" /* webpackChunkName: "component---src-pages-heroes-kolyada-tsx" */),
  "component---src-pages-heroes-korelin-tsx": () => import("./../../../src/pages/heroes/korelin.tsx" /* webpackChunkName: "component---src-pages-heroes-korelin-tsx" */),
  "component---src-pages-heroes-kornilov-tsx": () => import("./../../../src/pages/heroes/kornilov.tsx" /* webpackChunkName: "component---src-pages-heroes-kornilov-tsx" */),
  "component---src-pages-heroes-kotov-tsx": () => import("./../../../src/pages/heroes/kotov.tsx" /* webpackChunkName: "component---src-pages-heroes-kotov-tsx" */),
  "component---src-pages-heroes-kremnev-tsx": () => import("./../../../src/pages/heroes/kremnev.tsx" /* webpackChunkName: "component---src-pages-heroes-kremnev-tsx" */),
  "component---src-pages-heroes-kucherenko-tsx": () => import("./../../../src/pages/heroes/kucherenko.tsx" /* webpackChunkName: "component---src-pages-heroes-kucherenko-tsx" */),
  "component---src-pages-heroes-kudryavtsev-tsx": () => import("./../../../src/pages/heroes/kudryavtsev.tsx" /* webpackChunkName: "component---src-pages-heroes-kudryavtsev-tsx" */),
  "component---src-pages-heroes-kuznetsov-tsx": () => import("./../../../src/pages/heroes/kuznetsov.tsx" /* webpackChunkName: "component---src-pages-heroes-kuznetsov-tsx" */),
  "component---src-pages-heroes-lebedev-tsx": () => import("./../../../src/pages/heroes/lebedev.tsx" /* webpackChunkName: "component---src-pages-heroes-lebedev-tsx" */),
  "component---src-pages-heroes-makalov-tsx": () => import("./../../../src/pages/heroes/makalov.tsx" /* webpackChunkName: "component---src-pages-heroes-makalov-tsx" */),
  "component---src-pages-heroes-makarov-tsx": () => import("./../../../src/pages/heroes/makarov.tsx" /* webpackChunkName: "component---src-pages-heroes-makarov-tsx" */),
  "component---src-pages-heroes-makarskiy-tsx": () => import("./../../../src/pages/heroes/makarskiy.tsx" /* webpackChunkName: "component---src-pages-heroes-makarskiy-tsx" */),
  "component---src-pages-heroes-malikov-tsx": () => import("./../../../src/pages/heroes/malikov.tsx" /* webpackChunkName: "component---src-pages-heroes-malikov-tsx" */),
  "component---src-pages-heroes-manzyrev-tsx": () => import("./../../../src/pages/heroes/manzyrev.tsx" /* webpackChunkName: "component---src-pages-heroes-manzyrev-tsx" */),
  "component---src-pages-heroes-mezinov-tsx": () => import("./../../../src/pages/heroes/mezinov.tsx" /* webpackChunkName: "component---src-pages-heroes-mezinov-tsx" */),
  "component---src-pages-heroes-mogilev-tsx": () => import("./../../../src/pages/heroes/mogilev.tsx" /* webpackChunkName: "component---src-pages-heroes-mogilev-tsx" */),
  "component---src-pages-heroes-negin-tsx": () => import("./../../../src/pages/heroes/negin.tsx" /* webpackChunkName: "component---src-pages-heroes-negin-tsx" */),
  "component---src-pages-heroes-novikov-tsx": () => import("./../../../src/pages/heroes/novikov.tsx" /* webpackChunkName: "component---src-pages-heroes-novikov-tsx" */),
  "component---src-pages-heroes-petrenko-tsx": () => import("./../../../src/pages/heroes/petrenko.tsx" /* webpackChunkName: "component---src-pages-heroes-petrenko-tsx" */),
  "component---src-pages-heroes-ptichka-tsx": () => import("./../../../src/pages/heroes/ptichka.tsx" /* webpackChunkName: "component---src-pages-heroes-ptichka-tsx" */),
  "component---src-pages-heroes-rogojko-tsx": () => import("./../../../src/pages/heroes/rogojko.tsx" /* webpackChunkName: "component---src-pages-heroes-rogojko-tsx" */),
  "component---src-pages-heroes-romanchuk-tsx": () => import("./../../../src/pages/heroes/romanchuk.tsx" /* webpackChunkName: "component---src-pages-heroes-romanchuk-tsx" */),
  "component---src-pages-heroes-ryjiy-tsx": () => import("./../../../src/pages/heroes/ryjiy.tsx" /* webpackChunkName: "component---src-pages-heroes-ryjiy-tsx" */),
  "component---src-pages-heroes-safronov-tsx": () => import("./../../../src/pages/heroes/safronov.tsx" /* webpackChunkName: "component---src-pages-heroes-safronov-tsx" */),
  "component---src-pages-heroes-samarskiy-tsx": () => import("./../../../src/pages/heroes/samarskiy.tsx" /* webpackChunkName: "component---src-pages-heroes-samarskiy-tsx" */),
  "component---src-pages-heroes-sandrigailo-tsx": () => import("./../../../src/pages/heroes/sandrigailo.tsx" /* webpackChunkName: "component---src-pages-heroes-sandrigailo-tsx" */),
  "component---src-pages-heroes-seleznev-tsx": () => import("./../../../src/pages/heroes/seleznev.tsx" /* webpackChunkName: "component---src-pages-heroes-seleznev-tsx" */),
  "component---src-pages-heroes-shaman-tsx": () => import("./../../../src/pages/heroes/shaman.tsx" /* webpackChunkName: "component---src-pages-heroes-shaman-tsx" */),
  "component---src-pages-heroes-sherbinin-tsx": () => import("./../../../src/pages/heroes/sherbinin.tsx" /* webpackChunkName: "component---src-pages-heroes-sherbinin-tsx" */),
  "component---src-pages-heroes-sheremetiev-tsx": () => import("./../../../src/pages/heroes/sheremetiev.tsx" /* webpackChunkName: "component---src-pages-heroes-sheremetiev-tsx" */),
  "component---src-pages-heroes-shipitsin-tsx": () => import("./../../../src/pages/heroes/shipitsin.tsx" /* webpackChunkName: "component---src-pages-heroes-shipitsin-tsx" */),
  "component---src-pages-heroes-sizov-tsx": () => import("./../../../src/pages/heroes/sizov.tsx" /* webpackChunkName: "component---src-pages-heroes-sizov-tsx" */),
  "component---src-pages-heroes-snezhkov-tsx": () => import("./../../../src/pages/heroes/snezhkov.tsx" /* webpackChunkName: "component---src-pages-heroes-snezhkov-tsx" */),
  "component---src-pages-heroes-solenov-tsx": () => import("./../../../src/pages/heroes/solenov.tsx" /* webpackChunkName: "component---src-pages-heroes-solenov-tsx" */),
  "component---src-pages-heroes-soloviev-p-tsx": () => import("./../../../src/pages/heroes/solovievP.tsx" /* webpackChunkName: "component---src-pages-heroes-soloviev-p-tsx" */),
  "component---src-pages-heroes-soloviev-tsx": () => import("./../../../src/pages/heroes/soloviev.tsx" /* webpackChunkName: "component---src-pages-heroes-soloviev-tsx" */),
  "component---src-pages-heroes-sotnikov-tsx": () => import("./../../../src/pages/heroes/sotnikov.tsx" /* webpackChunkName: "component---src-pages-heroes-sotnikov-tsx" */),
  "component---src-pages-heroes-spiridonov-tsx": () => import("./../../../src/pages/heroes/spiridonov.tsx" /* webpackChunkName: "component---src-pages-heroes-spiridonov-tsx" */),
  "component---src-pages-heroes-tolkachov-tsx": () => import("./../../../src/pages/heroes/tolkachov.tsx" /* webpackChunkName: "component---src-pages-heroes-tolkachov-tsx" */),
  "component---src-pages-heroes-uzdorbiev-tsx": () => import("./../../../src/pages/heroes/uzdorbiev.tsx" /* webpackChunkName: "component---src-pages-heroes-uzdorbiev-tsx" */),
  "component---src-pages-heroes-vasilyev-tsx": () => import("./../../../src/pages/heroes/vasilyev.tsx" /* webpackChunkName: "component---src-pages-heroes-vasilyev-tsx" */),
  "component---src-pages-heroes-vostroknutov-tsx": () => import("./../../../src/pages/heroes/vostroknutov.tsx" /* webpackChunkName: "component---src-pages-heroes-vostroknutov-tsx" */),
  "component---src-pages-heroes-yangirov-tsx": () => import("./../../../src/pages/heroes/yangirov.tsx" /* webpackChunkName: "component---src-pages-heroes-yangirov-tsx" */),
  "component---src-pages-heroes-zagretdinov-tsx": () => import("./../../../src/pages/heroes/zagretdinov.tsx" /* webpackChunkName: "component---src-pages-heroes-zagretdinov-tsx" */),
  "component---src-pages-heroes-zayka-tsx": () => import("./../../../src/pages/heroes/zayka.tsx" /* webpackChunkName: "component---src-pages-heroes-zayka-tsx" */),
  "component---src-pages-heroes-zaytsev-tsx": () => import("./../../../src/pages/heroes/zaytsev.tsx" /* webpackChunkName: "component---src-pages-heroes-zaytsev-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-movie-tsx": () => import("./../../../src/pages/movie.tsx" /* webpackChunkName: "component---src-pages-movie-tsx" */)
}

